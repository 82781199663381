var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "green-border-box in-padd white-bg" },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-xs-12 col-md-12" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.search,
                expression: "search"
              }
            ],
            staticClass: "search-box",
            attrs: { type: "search", q: "store", placeholder: "Search City" },
            domProps: { value: _vm.search },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.search = $event.target.value
              }
            }
          })
        ])
      ]),
      _vm.cities.length > 0
        ? _c(
            "div",
            { staticClass: "row" },
            _vm._l(_vm.cities, function(city) {
              return _c(
                "div",
                { key: city.id, staticClass: "col-xs-12 col-sm-6 item-list" },
                [
                  _c("CityItem", {
                    attrs: { city: city },
                    on: { getCityDetails: _vm.getCityDetails }
                  })
                ],
                1
              )
            }),
            0
          )
        : _c("h2", { staticStyle: { "text-align": "center" } }, [
            _vm._v("No City Found in system!")
          ]),
      _vm.loadingCities
        ? _c("h2", { staticStyle: { "text-align": "center" } }, [
            _vm._v("Loading Cities...")
          ])
        : _vm._e(),
      _vm.hasMoreCities && !_vm.loadingCities
        ? _c(
            "div",
            { staticClass: "text-center", on: { click: _vm.getCities } },
            [
              _c("button", { staticClass: "btn-positive" }, [
                _vm._v("Load more")
              ])
            ]
          )
        : _vm._e(),
      _c("CityPopup", {
        attrs: {
          editCityFlag: false,
          city: _vm.cityDetails,
          popupId: "add-targeted-city-popup"
        },
        on: { refreshStoreLocationsList: _vm.reFilter }
      }),
      _c("CityPopup", {
        attrs: {
          editCityFlag: true,
          city: _vm.cityDetails,
          popupId: "edit-city-popup"
        },
        on: { refreshStoreLocationsList: _vm.reFilter }
      }),
      _c("DeleteCityPopup", {
        attrs: { city: _vm.cityDetails, popupId: "delete-city-popup" },
        on: { cityDeleted: _vm.cityDeleted }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }