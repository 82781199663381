









































import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import { Brand } from '@/components/SAdmin/BrandItem.vue'

@Component
export default class DeleteCityPopup extends Vue {
  @Prop() popupId!: string
  @Prop() city!: any

  deleteLocation () {
    this.$http
      .delete(process.env.VUE_APP_API_BASE_URL + '/state-cities/' + this.city.id, {
        headers: {
          'user-type': 'sadmin'
        }
      })
      .then((response) => {
        let DeleteCityCloseButtonPopup: HTMLElement = this.$refs.DeleteCityCloseButtonPopup as HTMLElement
        this.$emit('cityDeleted')
        DeleteCityCloseButtonPopup.click()
        this.$notify({ type: 'success', text: 'City has been deleted successfully!' })
      })
  }
}
